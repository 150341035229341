import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c879d9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preloader" }
const _hoisted_2 = {
  key: 0,
  class: "preloader__gate"
}
const _hoisted_3 = {
  key: 1,
  class: "preloader__gate"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.isReload)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_ctx.isEnter && !_ctx.isGameLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Loader)
          ]))
        : _createCommentVNode("", true)
  ]))
}