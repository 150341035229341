
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Scale',

  props: {
    face: {
      type: String,
      default: null,
      required: true,
    },
    progress: {
      type: Number,
      default: null,
      required: true,
    },
    lock: {
      type: Boolean,
      default: false,
      required: false,
    },
    not: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
