import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["scale", [_ctx.lock && 'scale--lock', _ctx.not && 'effect-scale']])
  }, [
    (_ctx.progress > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["scale__progress", `scale__progress--${_ctx.face}`]),
          style: _normalizeStyle({ width: _ctx.progress + '%' })
        }, null, 6))
      : _createCommentVNode("", true)
  ], 2))
}