// Events Helper
//////////////////////////////////////////////////////

// Constants
import { DESIGN } from '@/utils/constants';

// Types
import type { ISelf } from '@/models/modules';
import type { TEvents, TEventsData } from '@/models/utils';
import type { ISendMessage } from '@/models/api';
import { Clock } from 'three';

export default class Events {
  public delta!: number;

  private _clock: Clock;
  private _bus!: Array<TEvents>;
  private _id!: number;
  private _pause!: number;

  constructor() {
    this._clock = new Clock(false);
    this.delta = this._clock.getDelta();
    this._bus = [];
    this._id = 1;
  }

  // Добавить запись
  public addEventsToBus(
    delay: number,
    data: TEventsData,
    callback: (data: TEventsData) => void,
  ): void {
    this._bus.push({
      id: this._id,
      time: 0,
      delay,
      data,
      callback,
    });
    ++this._id;
  }

  // Удалить запись
  private _removeEventsFromBus(id: number): void {
    // eslint-disable-next-line no-const-assign
    this._bus = this._bus.filter((record) => record.id !== id);
  }

  // Задержка события
  public delayDispatchHelper(
    delay: number,
    callback: (data: TEventsData) => void,
  ): void {
    this._pause = delay || DESIGN.ANIMATION_TIMEOUT / 1000;

    this.addEventsToBus(this._pause, null, (data) => callback(data));
  }

  private _beep(): void {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const gainNode = new GainNode(ctx, {
      gain: 0.1,
    });
    oscillator.frequency.value = 330;
    oscillator.connect(gainNode).connect(ctx.destination);
    oscillator.start();
    oscillator.stop(0.3);
  }

  // Помощник показа коротких экранных сообщений
  public messagesByIdDispatchHelper(
    self: ISelf,
    text: string,
    delay?: number,
    payload?: string,
  ): void {
    this._pause = delay || DESIGN.MESSAGES_TIMEOUT / 1000;

    // Сообщение пришло в чат
    if (payload) {
      self.store
        .dispatch('not/showMessage', { id: this._id, text: payload })
        .catch((error) => {
          console.log(error);
        }).then(() => {
          this._beep();
        });
    } else {
      // Остальные
      self.store
        .dispatch('not/showMessage', { id: this._id, text })
        .catch((error) => {
          console.log(error);
        }).then(() => {
          this._beep();
        });
    }

    this.addEventsToBus(this._pause, this._id, (data) => {
      self.store.dispatch('not/hideMessage', data);
    });
  }

  // Помощник показа длинных экранных сообщений
  public showPermanentMessage(
    self: ISelf,
    text: string,
    delay?: number,
  ): void {
    this._pause = delay || DESIGN.MESSAGES_TIMEOUT / 1000;

    self.store
      .dispatch('not/showPermanentMessage', { id: null, text })
      .catch((error) => {
        console.log(error);
      });
  }

  public animate(): void {
    if (!this._clock.running) this._clock.start();

    this.delta = this._clock.getDelta();
    this._bus.forEach((record) => {
      record.time += this.delta;
      if (record.time > record.delay) {
        record.callback(record.data as number);
        this._removeEventsFromBus(record.id);
      }
    });
  }
}
