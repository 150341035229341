
import { defineComponent, computed, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Constants
import { LANGUAGES } from '@/utils/constants';

// Types
import { TLanguage } from '@/models/store';

export default defineComponent({
  name: 'LangSwitch',

  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    });

    const store = useStore(key);

    const language = computed(() => store.getters['persist/language']);

    const changeLanguage = (value: TLanguage): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      locale.value = value;
      store.dispatch('persist/setPersistState', { field: 'language', value });
    };

    const languages = LANGUAGES.map((language): TLanguage => {
      return language;
    });

    onMounted(() => {
      // Позорный кряк ))) для подгрузки второго языка, чтобы не "моргал" при первой загрузке и первом переключении
      if (language.value === LANGUAGES[0]) {
        changeLanguage(LANGUAGES[1]);
        nextTick(() => {
          changeLanguage(LANGUAGES[0]);
        });
      } else {
        changeLanguage(LANGUAGES[0]);
        nextTick(() => {
          changeLanguage(LANGUAGES[1]);
        });
      }
    });

    return {
      t,
      language,
      languages,
      changeLanguage,
    };
  },
});
