
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';

// Components
import Loader from '@/components/Layout/Loader.vue';

export default defineComponent({
  name: 'Preloader',

  components: {
    Loader,
  },

  setup() {
    const store = useStore(key);

    const isEnter = computed(() => store.getters['persist/isEnter']);
    const isGameLoaded = computed(
      () => store.getters['preloader/isGameLoaded'],
    );

    const isReload = computed(() => store.getters['not/isReload']);

    return {
      isEnter,
      isGameLoaded,
      isReload,
    };
  },
});
