
import { defineComponent, ref, Ref, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Constants
import { Races, Things } from '@/utils/constants';

export default defineComponent({
  name: 'Help',

  setup() {
    const { t } = useI18n();
    const store = useStore(key);

    const active: Ref<number> = ref(1);
    const config = computed(() => store.getters['persist/config']);
    let close: () => void;

    close = () => {
      store.dispatch('not/setNotState', {
        field: 'isHelp',
        value: false,
      });
    };

    return {
      t,
      active,
      Races,
      Things,
      config,
      close,
    };
  },
});
