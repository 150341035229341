
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Gate',

  props: {
    face: {
      type: String,
      default: null,
      required: true,
    },
  },
});
