import * as THREE from 'three';

// Types
import type { Group, Mesh } from 'three';
import type { ISelf } from '@/models/modules';
import type { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import type { IShot, IUnitInfo, IHitsUpdate } from '@/models/api';

// Constants
import {
  Races,
  Audios,
  Names,
  Textures,
  Picks,
  Things as ThingsEnum,
  LANGUAGES,
} from '@/utils/constants';
import { EmitterEvents } from '@/models/api';

// Modules
import Atmosphere from '@/components/Scene/World/Atmosphere/Atmosphere';
import Players from '@/components/Scene/World/Players';
import NPC from '@/components/Scene/World/Enemies/NPC';
import Shots from '@/components/Scene/World/Weapon/Shots';
import Lights from '@/components/Scene/World/Weapon/Lights';
import Explosions from '@/components/Scene/World/Weapon/Explosions';
import Bloods from '@/components/Scene/World/Atmosphere/Bloods';
import Things from '@/components/Scene/World/Atmosphere/Things';
import Octree from '@/components/Scene/World/Math/Octree';

// Utils
import emitter from '@/utils/emitter';

export default class World {
  public name = Names.world;

  private _group!: Group;
  private _mesh!: Mesh;
  private _pseudo!: Mesh;
  private _list!: IUnitInfo[];
  private _deads!: Mesh[];
  private _tngs!: Mesh[];

  // Modules
  private _athmosphere: Atmosphere;
  private _players: Players;
  private _shots: Shots;
  private _lights: Lights;
  private _explosions: Explosions;
  private _bloods: Bloods;
  private _things: Things;
  private _npc: NPC;
  private _time = 0;
  private _time2 = 0;
  private _number!: number;
  private _string!: string;

  constructor() {
    // Modules
    this._athmosphere = new Atmosphere();
    this._players = new Players();
    this._shots = new Shots();
    this._lights = new Lights();
    this._explosions = new Explosions();
    this._bloods = new Bloods();
    this._things = new Things();
    this._npc = new NPC();

    this._group = new THREE.Group();
    this._deads = [];
    this._tngs = [];
  }

  public init(self: ISelf): void {
    self.assets.GLTFLoader.load('./images/models/ground.glb', (model: GLTF) => {
      self.helper.loaderDispatchHelper(self.store, this.name);
      this._group = model.scene;
      this._group.position.y = -1;

      // Modules
      this._players.init(self);
      this._npc.init(self);
      this._athmosphere.init(self);
      this._shots.init(self);
      this._lights.init(self);
      this._explosions.init(self);
      this._bloods.init(self);
      this._things.init(self);

      // Реагировать на открытие двери
      emitter.on(EmitterEvents.door, (door) => {
        this._athmosphere.door(self, door as string);
      });

      // Реагировать на необходимость обновить двери
      emitter.on(EmitterEvents.doors, () => {
        this._updateOctre4(self);
      });

      // Игрок поставил флаг на контрольной точке
      emitter.on(EmitterEvents.point, (payload: any) => {
        // console.log('World EmitterEvents.point', payload);
        this._athmosphere.setFlag(payload.race);
      });

      // Реагировать на новый труп
      emitter.on(EmitterEvents.dead, (message: any) => {
        this._pseudo = self.scene.getObjectByProperty(
          'uuid',
          message.mesh as string,
        ) as Mesh;
        if (this._pseudo) {
          this._deads.push(this._pseudo);
        }
      });

      // Реагировать на новую вещь
      emitter.on(EmitterEvents.addThing, (message: any) => {
        this._pseudo = self.scene.getObjectByProperty(
          'uuid',
          message as string,
        ) as Mesh;
        if (this._pseudo) {
          this._tngs.push(this._pseudo);
        }
      });

      // Реагировать на удаление вещи
      emitter.on(EmitterEvents.removeThing, (id: any) => {
        // console.log('World EmitterEvents.removeThing!!!', id);
        this._tngs = this._tngs.filter((box) => box.uuid !== id);
      });

      // На ответ на сообщение в чат
      emitter.on(EmitterEvents.onSend, (payload: any) => {
        // А вот и коряка!!!))
        if (self.store.getters['persist/language'] === LANGUAGES[0]) {
          if (payload.race === Races.reptiloid) this._string = 'Reptiloid';
          else this._string = 'Russian rebel';
        } else {
          if (payload.race === Races.reptiloid) this._string = 'Рептилод';
          else this._string = 'Руский Повстанец';
        }
        self.events.messagesByIdDispatchHelper(
          self,
          '',
          6,
          this._string +
            ' ' +
            payload.name +
            ' на ' +
            payload.location +
            ': ' +
            payload.text,
        );
      });

      // Реагировать на подбор
      emitter.on(EmitterEvents.pick, (message: any) => {
        // console.log('World EmitterEvents.pick!!!', message);
        this._removeObject(message);

        if (message.type === Picks.thing) {
          switch (message.target) {
            case ThingsEnum.grenades:
              this._number =
                self.store.getters['persist/grenades'] +
                self.store.getters['persist/config'].things[ThingsEnum.grenades]
                  .pick;
              if (
                this._number <=
                self.store.getters['persist/config'].things[ThingsEnum.grenades]
                  .max
              ) {
                self.store.dispatch('persist/setPersistState', {
                  field: 'grenades',
                  value: this._number,
                });
              } else {
                self.store.dispatch('persist/setPersistState', {
                  field: 'grenades',
                  value:
                    self.store.getters['persist/config'].things[
                      ThingsEnum.grenades
                    ].max,
                });
              }
              break;
            case ThingsEnum.vodka:
              self.store.dispatch('persist/setPersistState', {
                field: 'vodka',
                value: self.store.getters['persist/vodka'] + 1,
              });
              break;
            case ThingsEnum.stew:
              self.store.dispatch('persist/setPersistState', {
                field: 'stew',
                value: self.store.getters['persist/stew'] + 1,
              });
              break;
          }
        }
      });

      // Реагировать на ответ на подбор
      emitter.on(EmitterEvents.onOnPick, (message: any) => {
        // console.log('World EmitterEvents.onOnPick!!!', message);
        if (message.user !== self.store.getters['persist/id'])
          this._removeObject(message);
      });
    });
  }

  // Удаление после ответа на подбор
  private _removeObject(message: any) {
    switch (message.type) {
      case Picks.dead:
        this._deads = this._deads.filter((box) => box.uuid !== message.uuid);
        break;
      case Picks.thing:
        this._tngs = this._tngs.filter((box) => box.uuid !== message.uuid);
        break;
    }
  }

  // Улучшение после того как локация построена
  public upgrade(self: ISelf): void {
    this._athmosphere.world.forEach((mesh) => {
      this._group.add(mesh);
    });

    // Создаем октодерево
    self.octree.fromGraphNode(this._group);

    self.scene.add(this._group);
    this._group.remove();

    // Нулевой элемент для второго дерева
    const pseudoGeometry = new THREE.BoxBufferGeometry(1, 1, 1);
    this._mesh = new THREE.Mesh(
      pseudoGeometry,
      self.assets.getMaterial(Textures.pseudo),
    );
    this._mesh.position.y = -3;

    // Создаем октодерево из дверей
    this._updateOctre4(self);

    // Добавляем звуки на двери
    this._athmosphere.doors.forEach((mesh) => {
      self.audio.addAudioOnObject(self, mesh.uuid, Audios.door);
      self.audio.setPlaybackRateOnObjectSound(mesh.uuid, Audios.door, 0.5);
    });

    self.render();
    self.helper.loaderDispatchHelper(self.store, this.name, true);
  }

  // Пересоздание октодерева дверей
  private _updateOctre4(self: ISelf): void {
    this._group = new THREE.Group();
    this._athmosphere.doors.forEach((mesh) => {
      this._group.add(mesh);
    });

    // Пересоздаем октодерево
    self.octree4 = new Octree();
    self.octree4.fromGraphNode(this._group);

    self.scene.add(this._group);
    this._group.remove();
  }

  // Пересоздание октодерева из ближайших игроков и неписей
  private _updateOctree2(self: ISelf): void {
    this._group = new THREE.Group();
    this._group.add(this._mesh);
    this._list
      .filter(
        (item) =>
          new THREE.Vector3(
            item.positionX,
            item.positionY,
            item.positionZ,
          ).distanceTo(self.camera.position) < 5,
      )
      .sort(
        (a, b) =>
          new THREE.Vector3(a.positionX, a.positionY, a.positionZ).distanceTo(
            self.camera.position,
          ) -
          new THREE.Vector3(b.positionX, b.positionY, b.positionZ).distanceTo(
            self.camera.position,
          ),
      )
      .slice(0, 3)
      .forEach((unit: IUnitInfo) => {
        this._pseudo = self.scene.getObjectByProperty(
          'uuid',
          unit.pseudo,
        ) as Mesh;
        if (this._pseudo) this._group.add(this._pseudo);
      });
    self.scene.add(this._group);
    self.octree2 = new Octree();
    self.octree2.fromGraphNode(this._group);
    this._group.remove();
  }

  // Пересоздание октодерева из игроков и неписей которых видит игрок
  private _updateOctree3(self: ISelf): void {
    this._group = new THREE.Group();
    this._group.add(this._mesh);
    this._list.forEach((unit: IUnitInfo) => {
      this._pseudo = self.scene.getObjectByProperty(
        'uuid',
        unit.pseudo,
      ) as Mesh;
      if (this._pseudo) this._group.add(this._pseudo);
    });
    self.scene.add(this._group);
    self.octree3 = new Octree();
    self.octree3.fromGraphNode(this._group);
    this._group.remove();
  }

  // Взять живых видимых игроком игроков и неписей
  private _getNotDeadVisibleUnits(): IUnitInfo[] {
    return this._players.getList().concat(this._npc.getList());
  }

  // Пиф-паф!
  public shot(self: ISelf): IShot | null {
    return this._players.shot(self);
  }

  // Ответ на выстрел
  public onShot(self: ISelf, shot: IShot): void {
    this._shots.onShot(self, shot);
  }

  // Прилетел урон
  public onHit(self: ISelf, ids: IHitsUpdate): void {
    // console.log('World onHit: ', ids);
    this._players.onHit(self, ids.users);
    this._bloods.onHit(
      self,
      this._list.filter((unit: IUnitInfo) =>
        [...ids.users, ...ids.npc].includes(unit.id),
      ),
    );
  }

  public animate(self: ISelf): void {
    this._time += self.events.delta;
    if (this._time > 0.25) {
      this._list = this._getNotDeadVisibleUnits();
      this._updateOctree2(self);
      this._updateOctree3(self);
      this._time = 0;
    }

    if (!self.store.getters['persist/isGameOver']) {
      this._time2 += self.events.delta;
      if (this._time2 > 1) {
        this._players.check(self, this._athmosphere.zones);
        this._time2 = 0;
      }
    }

    this._players.animate(
      self,
      this._athmosphere.world
        .concat(this._athmosphere.doors)
        .concat(this._athmosphere.point)
        .concat(this._deads)
        .concat(this._tngs),
    );
    this._npc.animate(self);
    this._athmosphere.animate(self);
    this._shots.animate(self, this._list);
    this._lights.animate(self);
    this._explosions.animate(self);
    this._bloods.animate(self);
    this._things.animate(self);
  }
}
